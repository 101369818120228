import { Button, Card, Col, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap"
import { useState, useEffect, useMemo } from "react"
import { useGetMerchantTermsQuery, useListMerchantsCompleteQuery, useListNetworksQuery, useUpdateMerchantMutation, useUpdateMerchantTermsMutation } from "../../Store/Merchants/Merchants.service"
import { IMerchant } from "@esavvynpm/types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faEye, faGlobe, faImage, faPen, faPercentage, faSign } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Notification/Notification.slice"
import { Link, useNavigate } from "react-router-dom"

function ListSearch() {
    const [search, setSearch] = useState('')
    const [filterInactive, setFilterInactive] = useState(true)
    const [displayedMerchants, setDisplayedMerchants] = useState<IMerchant[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const { data: merchants, isLoading: isLoadingMerchants } = useListMerchantsCompleteQuery()
    const { data: networks, isLoading: isLoadingNetworks } = useListNetworksQuery()

    function filter(value: IMerchant) {
        if (!(search?.length > 1)) {
            return false
        }


        if (!value.isActive && filterInactive) {
            return false
        }

        const byName = value.name.toLowerCase().includes(search.toLowerCase())
        const byNetwork = value.network?.toLowerCase().includes(search.toLowerCase())
        const byNetworkMerchantId = value.networkMerchantId?.toLowerCase().includes(search.toLowerCase())

        const byOriginalBaseLink = value.originalBaseLink?.toLowerCase().includes(search.toLowerCase())
        const byHostname = value.hostnames?.some(hostname => hostname.toLowerCase().includes(search.toLowerCase()))

        return byName || byNetwork || byNetworkMerchantId || byOriginalBaseLink || byHostname
    }

    useEffect(() => {
        setIsLoading(isLoadingMerchants || isLoadingNetworks)
    }, [isLoadingMerchants, isLoadingNetworks])

    useEffect(() => {
        if (!isLoading && search?.length > 1) {
            setDisplayedMerchants(merchants?.filter(filter) ?? [])
        } else {
            setDisplayedMerchants([])
        }
    }, [merchants, isLoading, search, filterInactive])

    return (
        <Row>
            <Col>
                <SearchBar
                    visible={!isLoading}
                    onSearchChange={setSearch}
                    disabledSearch={isLoading}
                    filterInactive={filterInactive}
                    setFilterInactive={setFilterInactive}
                    total={merchants?.length ?? 0}
                    totalShown={displayedMerchants.length}
                />
                <Loading isLoading={isLoading} />
                <Merchants
                    visible={!isLoading}
                    merchants={displayedMerchants}
                    networks={networks ?? []}
                />
            </Col>
        </Row>
    )
}

export default ListSearch

function SearchBar({
    visible,
    onSearchChange,
    disabledSearch,
    filterInactive,
    setFilterInactive,
    totalShown,
    total,
}: {
    visible: boolean
    onSearchChange: (value: string) => void
    disabledSearch: boolean
    filterInactive: boolean
    setFilterInactive: (value: boolean) => void
    totalShown: number
    total: number
}) {

    const navigate = useNavigate()

    let view = null
    if (visible) {
        view = <Row>
            <Col xs={12} md="auto" className="mb-3 text-end">
                <Button
                    onClick={() => navigate('/merchants/add')}
                >Add Merchant</Button>
            </Col>
            <Col xs={12} md="auto" className="mb-3">
                <InputGroup>
                    <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
                    <Form.Control
                        autoFocus
                        placeholder="keywords"
                        onChange={event => onSearchChange(event.target.value)}
                        disabled={disabledSearch}
                    />
                </InputGroup>
            </Col>
            <Col xs={12} md="auto" className="text-end mb-3">
                Total: {totalShown} / {total}&nbsp;
                <Button variant="outline-secondary" onClick={() => setFilterInactive(!filterInactive)}>
                    {filterInactive ? 'Show all' : 'Show active only'}
                </Button>
            </Col>
        </Row>
    }

    return view
}

function Loading({
    isLoading,
}: {
    isLoading: boolean
}) {
    let view = null
    if (isLoading) {
        view = <Row>
            <Col>Loading merchants... <Spinner /></Col>
        </Row>
    }

    return view
}

function Merchants({
    visible,
    merchants,
    networks,
}: {
    visible: boolean
    merchants: IMerchant[],
    networks: string[]
}) {
    let view = null
    if (visible) {
        view = <Row xs={1} md={2} className="g-4">
            {merchants.map(merchant => <Col xs={12} md={3} key={merchant._id}>
                <MerchantCard merchant={merchant} networks={networks ?? []} />
            </Col>)}
        </Row>
    }

    return view
}

function MerchantCard({
    merchant,
    networks
}: {
    merchant: IMerchant,
    networks: string[]
}) {

    const dispatch = useDispatch<AppDispatch>()

    const [editName, setEditName] = useState(false)
    const [editUrl, setEditUrl] = useState(false)
    const [editNetworkMerchantId, setEditNetworkMerchantId] = useState(false)
    const [editNetwork, setEditNetwork] = useState(false)
    const [editHostnames, setEditHostnames] = useState(false)
    const [editMaxCommissionPercentage, setEditMaxCommissionPercentage] = useState(false)
    const [editTerms, setEditTerms] = useState(false)

    let className: string | undefined = 'bg-secondary text-white'
    if (merchant.isActive) {
        className = undefined
    }

    let textClassName: string | undefined
    if (!merchant.isActive) {
        textClassName = 'text-white'
    }

    const copyToClipboard = (text?: string) => {
        if (text) {
            navigator.clipboard.writeText(text)
            dispatch(setMessage({
                message: 'Copied to clipboard.',
                variant: 'success',
            }))
        }
    }

    const hostname = useMemo(() => {
        return new URL(merchant.originalBaseLink).hostname.replace('www.', '')
    }, [merchant.originalBaseLink])

    return (
        <Card className={className}>
            <Card.Body>
                <Card.Title>
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => setEditName(!editName)}
                    >
                        {merchant.name}
                    </span>
                </Card.Title>
                <Card.Text>
                    <Row>
                        <Col style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            <FontAwesomeIcon
                                icon={faPen}
                                size="sm"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setEditUrl(!editUrl)}
                            /> <a
                                className={textClassName}
                                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                href={merchant.originalBaseLink}
                                target="_blank"
                                rel="noopener noreferrer">
                                {hostname}
                            </a>
                        </Col>
                        {merchant.imageUrl && <Col className="text-end" xs="auto" style={{ minHeight: 50 }}><img src={merchant.imageUrl} style={{ maxHeight: 50, maxWidth: 50 }} /></Col>}
                    </Row>
                    {!merchant.imageUrl && <Row className="text-danger">
                        <Col>
                            <FontAwesomeIcon icon={faImage} /> missing image
                        </Col>
                    </Row>}
                    {merchant.network || merchant.networkMerchantId ? <Row>
                        <Col>
                            {merchant.networkMerchantId && <span>
                                <FontAwesomeIcon icon={faCopy} style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(merchant.networkMerchantId)} />&nbsp;
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setEditNetworkMerchantId(!editNetworkMerchantId)}
                                >{merchant.networkMerchantId}</span>
                            </span>}
                            {merchant.network && <span className="text-uppercase">
                                &nbsp;<span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setEditNetwork(!editNetwork)}
                                >{merchant.network}</span>
                            </span>}
                        </Col>
                    </Row> : null}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col xs="auto">
                        <FontAwesomeIcon
                            icon={faGlobe}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setEditHostnames(!editHostnames)}
                        />
                    </Col>
                    <Col xs="auto">
                        {merchant.maxCommissionPercent ?? '0'} <FontAwesomeIcon
                            icon={faPercentage}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setEditMaxCommissionPercentage(!editMaxCommissionPercentage)}
                        />
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon
                            icon={faSign}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setEditTerms(!editTerms)}
                        />
                    </Col>
                    <Col className="text-end">
                        <Link
                            to={`/merchants/${merchant._id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Open
                        </Link>
                    </Col>
                </Row>
            </Card.Footer>
            <EditNameModal
                merchant={merchant}
                edit={editName}
                setEdit={setEditName}
            />
            <EditURLModal
                merchant={merchant}
                edit={editUrl}
                setEdit={setEditUrl}
            />
            <EditNetworkMerchantIdModal
                merchant={merchant}
                edit={editNetworkMerchantId}
                setEdit={setEditNetworkMerchantId}
            />
            <EditNetworkModal
                merchant={merchant}
                edit={editNetwork}
                setEdit={setEditNetwork}
                networks={networks}
            />
            <EditHostnamesModal
                merchant={merchant}
                edit={editHostnames}
                setEdit={setEditHostnames}
            />
            <EditMaxCommissionPercentageModal
                merchant={merchant}
                edit={editMaxCommissionPercentage}
                setEdit={setEditMaxCommissionPercentage}
            />
            <EditTermsModal
                merchant={merchant}
                edit={editTerms}
                setEdit={setEditTerms}
            />
        </Card>
    )
}

function EditNameModal({
    merchant,
    edit,
    setEdit
}: {
    merchant: IMerchant
    edit: boolean, setEdit: (value: boolean) => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchant, { isLoading }] = useUpdateMerchantMutation()

    const [name, setName] = useState(merchant.name)

    const onSave = async () => {
        try {
            await updateMerchant({
                merchantId: merchant._id,
                body: {
                    name,
                },
            })
            setEdit(false)
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Edit name</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Control type="text" value={name} disabled={isLoading} onChange={event => setName(event.target.value)} />
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)} disabled={isLoading}>Close</Button>
            <Button variant="primary" onClick={onSave} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save changes'}</Button>
        </Modal.Footer>
    </Modal>
}

function EditURLModal({
    merchant,
    edit,
    setEdit
}: {
    merchant: IMerchant
    edit: boolean, setEdit: (value: boolean) => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchant, { isLoading }] = useUpdateMerchantMutation()

    const [url, setUrl] = useState(merchant.originalBaseLink)

    const onSave = async () => {
        try {
            await updateMerchant({
                merchantId: merchant._id,
                body: {
                    originalBaseLink: url,
                },
            })
            setEdit(false)
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Edit URL</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Control type="text" value={url} disabled={isLoading} onChange={event => setUrl(event.target.value)} />
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)} disabled={isLoading}>Close</Button>
            <Button variant="primary" onClick={onSave} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save changes'}</Button>
        </Modal.Footer>
    </Modal>
}

function EditNetworkMerchantIdModal({
    merchant,
    edit,
    setEdit
}: {
    merchant: IMerchant
    edit: boolean, setEdit: (value: boolean) => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchant, { isLoading }] = useUpdateMerchantMutation()

    const [networkMerchantId, setNetworkMerchantId] = useState(merchant.networkMerchantId)

    const onSave = async () => {
        try {
            await updateMerchant({
                merchantId: merchant._id,
                body: {
                    networkMerchantId,
                },
            })
            setEdit(false)
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Network Merchant ID</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Control type="text" value={networkMerchantId} disabled={isLoading} onChange={event => setNetworkMerchantId(event.target.value)} />
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)} disabled={isLoading}>Close</Button>
            <Button variant="primary" onClick={onSave} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save changes'}</Button>
        </Modal.Footer>
    </Modal>
}

function EditNetworkModal({
    merchant,
    edit,
    setEdit,
    networks
}: {
    merchant: IMerchant
    edit: boolean,
    setEdit: (value: boolean) => void
    networks: string[]
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchant, { isLoading }] = useUpdateMerchantMutation()

    const [network, setNetwork] = useState(merchant.network)

    const onSave = async () => {
        try {
            await updateMerchant({
                merchantId: merchant._id,
                body: {
                    network,
                },
            })
            setEdit(false)
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Network</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Select value={network} disabled={isLoading} onChange={event => setNetwork(event.target.value)}>
                {networks.map(network => <option key={network} value={network}>{network}</option>)}
            </Form.Select>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)} disabled={isLoading}>Close</Button>
            <Button variant="primary" onClick={onSave} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save changes'}</Button>
        </Modal.Footer>
    </Modal>
}

function EditHostnamesModal({
    merchant,
    edit,
    setEdit
}: {
    merchant: IMerchant
    edit: boolean
    setEdit: (value: boolean) => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchant, { isLoading }] = useUpdateMerchantMutation()

    const [hostnames, setHostnames] = useState<string[]>(merchant.hostnames ?? [])

    const onSave = async () => {
        try {
            await updateMerchant({
                merchantId: merchant._id,
                body: {
                    hostnames: hostnames.join('\n'),
                },
            })
            setEdit(false)
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Hostnames</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Control
                type="text"
                as="textarea"
                rows={4}
                placeholder="www.example.com"
                value={hostnames?.join('\n')}
                onChange={event => setHostnames(event.target.value.split('\n'))}
            />
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)} disabled={isLoading}>Close</Button>
            <Button variant="primary" onClick={onSave} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save changes'}</Button>
        </Modal.Footer>
    </Modal>
}

function EditMaxCommissionPercentageModal({
    merchant,
    edit,
    setEdit
}: {
    merchant: IMerchant
    edit: boolean
    setEdit: (value: boolean) => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchant, { isLoading }] = useUpdateMerchantMutation()

    const [maxCommissionPercent, setMaxCommissionPercent] = useState(merchant.maxCommissionPercent)

    const onSave = async () => {
        try {
            await updateMerchant({
                merchantId: merchant._id,
                body: {
                    maxCommissionPercent,
                },
            })
            setEdit(false)
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Edit max commission percentage</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Control type="number" value={maxCommissionPercent} disabled={isLoading} onChange={event => setMaxCommissionPercent(Number(event.target.value))} />
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)} disabled={isLoading}>Close</Button>
            <Button variant="primary" onClick={onSave} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save changes'}</Button>
        </Modal.Footer>
    </Modal>
}

function EditTermsModal({
    merchant,
    edit,
    setEdit
}: {
    merchant: IMerchant
    edit: boolean,
    setEdit: (value: boolean) => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchantTerms, { isLoading }] = useUpdateMerchantTermsMutation()

    const { data: merchantTerms, isLoading: isLoadingTerms } = useGetMerchantTermsQuery(merchant._id)

    const [terms, setTerms] = useState(merchantTerms?.terms ?? '')

    useEffect(() => {
        setTerms(merchantTerms?.terms ?? '')
    }, [merchantTerms])

    const onSave = async () => {
        try {
            await updateMerchantTerms({
                merchantId: merchant._id,
                body: {
                    terms,
                },
            })
            setEdit(false)
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Edit terms and conditions</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form.Control
                type="text"
                as="textarea"
                rows={4}
                value={terms}
                onChange={event => setTerms(event.target.value)}
            />
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={() => setEdit(false)} disabled={isLoading || isLoadingTerms}>Close</Button>
            <Button variant="primary" onClick={onSave} disabled={isLoading || isLoadingTerms}>{isLoading ? 'Saving...' : 'Save changes'}</Button>
        </Modal.Footer>
    </Modal>
}