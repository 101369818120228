import { useEffect, useState } from "react";
import { useListMerchantScrapQuery, useUpdateMerchantScrapMutation } from "../../Store/Merchants/MerchantScrap.service";
import { IMerchantScrap } from "@esavvynpm/types";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import MerchantScrapItem from "./merchant-scrap-item";
import { useImportMerchantScrapsMutation, useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import MerchantScrapItemEdit from "./merchant-scrap-item-edit";
import MerchantScrapItemSchedule from "./merchant-scrap-item-schedule";
import MerchantScrapFilter from "./merchant-scrap-filter";
import { AdvTableFilterParams } from "../AdvTable/types";

function MerchantScrap() {

    const dispatch = useDispatch<AppDispatch>()
    const [showFilter, setShowFilter] = useState(false)
    const [merchantScrapEdit, setMerchantScrapEdit] = useState<IMerchantScrap | null>(null)
    const [merchantScrapSchedule, setmerchantScrapSchedule] = useState<IMerchantScrap | null>(null)
    const [merchantNames, setMerchantNames] = useState<{ [index: string]: string }>({})
    const [filters, setFilters] = useState<AdvTableFilterParams[]>([])
    const [items, setItems] = useState<{
        enabled: IMerchantScrap[]
        scheduled: IMerchantScrap[]
        disabled: IMerchantScrap[]
    }>({
        enabled: [],
        scheduled: [],
        disabled: [],
    })

    const merchantScrapQuery = useListMerchantScrapQuery()
    const merchantsQuery = useListMerchantsCompleteQuery()

    const isLoading = merchantScrapQuery.isLoading || merchantScrapQuery.isFetching || merchantsQuery.isLoading || merchantsQuery.isFetching

    const [updateMerchantScrap] = useUpdateMerchantScrapMutation()
    const [importMerchantScraps, importMerchantScrapsResult] = useImportMerchantScrapsMutation()

    useEffect(() => {
        if (merchantScrapQuery.isSuccess) {

            const enabled: IMerchantScrap[] = []
            const scheduled: IMerchantScrap[] = []
            const disabled: IMerchantScrap[] = []
            for (const record of merchantScrapQuery.data) {
                if (record.isDisabled) {
                    disabled.push(record)
                } else {
                    enabled.push(record)
                }
            }
            setItems({
                enabled,
                scheduled,
                disabled,
            })
        }
    }, [merchantScrapQuery.isSuccess, merchantScrapQuery.data])

    useEffect(() => {
        if (merchantsQuery.isSuccess) {
            const filters: AdvTableFilterParams[] = []
            const merchantNames: { [index: string]: string } = {}
            for (const record of merchantsQuery.data) {
                merchantNames[record._id] = record.name
                filters.push({
                    field: 'merchantId',
                    filter: record._id,
                    id: record._id,
                    label: record.name,
                })
            }
            setMerchantNames(merchantNames)
            setFilters(filters)
        }
    }, [merchantsQuery.isSuccess, merchantsQuery.data])

    const onDisable = async (item: IMerchantScrap, enable: boolean = false) => {
        try {
            await updateMerchantScrap({
                isDisabled: !enable,
                merchantScrapId: (item as any)._id,
            }).unwrap()
            dispatch(setMessage({
                message: 'Merchant scrap updated successfully',
                variant: 'success',
            }))
            merchantScrapQuery.refetch()
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    const onImportMerchantScraps = async () => {
        const confirm = window.confirm('Import merchant scraps from S3?')
        if (confirm) {
            try {
                const counterData = await importMerchantScraps().unwrap()
                dispatch(setMessage({
                    message: `Merchant scrap imported ${counterData.counter} new items`,
                    variant: 'success',
                }))
            } catch (err) {
                dispatch(setMessage({
                    message: (err as Error).message,
                    variant: 'danger',
                }))
            }
        }
    }

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Button
                        variant="primary"
                        onClick={onImportMerchantScraps}
                        disabled={importMerchantScrapsResult.isLoading}
                    >{importMerchantScrapsResult.isLoading ? 'Importing' : 'Import'}</Button>
                </Col>
                <Col>
                    <Button
                        variant="link"
                        onClick={() => setShowFilter(!showFilter)}
                    >Filter</Button>
                </Col>
            </Row>
            <Tabs
                defaultActiveKey="enabled"
                id="uncontrolled-tab"
                className="mb-3"
            >
                <Tab eventKey="enabled" title="Enabled">
                    <Row>
                        {isLoading ? <Col>Loading...</Col> : null}
                        {items.enabled.map(item => <MerchantScrapItem
                            key={(item as any)._id}
                            item={item}
                            merchantNames={merchantNames}
                            onDisable={onDisable}
                            category={'enabled'}
                            onEdit={merchantScrap => setMerchantScrapEdit(merchantScrap)}
                            onSchedule={merchantScrap => setmerchantScrapSchedule(merchantScrap)}
                        />)}
                    </Row>
                </Tab>
                <Tab eventKey="scheduled" title="Scheduled">
                    <Row>
                        {isLoading ? <Col>Loading...</Col> : null}
                        {items.scheduled.map(item => <MerchantScrapItem
                            key={(item as any)._id}
                            item={item}
                            merchantNames={merchantNames}
                            category={'scheduled'}
                            onEdit={merchantScrap => setMerchantScrapEdit(merchantScrap)}
                        />)}
                    </Row>
                </Tab>
                <Tab eventKey="disabled" title="Disabled">
                    <Row>
                        {isLoading ? <Col>Loading...</Col> : null}
                        {items.disabled.map(item => <MerchantScrapItem
                            key={(item as any)._id}
                            item={item}
                            merchantNames={merchantNames}
                            category={'disabled'}
                            onEdit={merchantScrap => setMerchantScrapEdit(merchantScrap)}
                            onEnable={merchantScrap => onDisable(merchantScrap, true)}
                        />)}
                    </Row>
                </Tab>
            </Tabs>
            {merchantScrapEdit ? <MerchantScrapItemEdit
                merchantScrapEdit={merchantScrapEdit}
                handleClose={() => {
                    setMerchantScrapEdit(null)
                }}
            /> : null}
            {merchantScrapSchedule ? <MerchantScrapItemSchedule
                merchantScrapSchedule={merchantScrapSchedule}
                handleClose={() => {
                    setmerchantScrapSchedule(null)
                }}
            /> : null}
            {filters.length ? <MerchantScrapFilter
                show={showFilter}
                filters={filters}
                selectedFilters={[]}
                handleClose={() => setShowFilter(false)}
                handleFilter={selectedFilters => {
                    console.log('test', selectedFilters)
                }}
            /> : null}
        </>
    );
}

export default MerchantScrap;
