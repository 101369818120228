import { Container } from "react-bootstrap";
import NavPage from "../NavPage";
import ListSearch from "./list-search";
import Categories from "./categories";

function Merchants() {

    return (
        <Container fluid>
            <NavPage
                pageTitle="Merchants"
                items={[{
                    label: 'List',
                    key: '/merchants/list',
                }, {
                    label: 'Categories',
                    key: '/merchants/categories',
                }]}
                children={{
                    '/merchants/list': <ListSearch />,
                    '/merchants/categories': <Categories />,
                }}
            />
        </Container>
    );
}

export default Merchants;
