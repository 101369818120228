import { useEffect, useState } from "react"
import { useListRegionsQuery } from "../../Store/Feed/Feed.service"
import Splash from "../Splash"
import Navigation from "../Navigation"

function Preload() {

    const regionsQuery = useListRegionsQuery()

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!regionsQuery.isLoading && regionsQuery.isSuccess) {
            setIsLoading(false)
        }
    }, [
        regionsQuery.isLoading,
        regionsQuery.isSuccess,
    ])

    let view = <Splash />
    if (!isLoading) {
        view = <Navigation />
    }

    return <>{view}</>
}

export default Preload