import { IFeedProduct, IRegion, IScheduledFeedProduct, PaginationResult } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { IScheduleFeedProductPublishRequest, PaginatedQueryPayload } from "../Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listFeedProducts: build.query<PaginationResult<IFeedProduct>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                if (params.customFilters) {
                    queryParams += `&filters=${btoa(JSON.stringify(params.customFilters))}`
                }
                return API_URLS.feedProductsPaginated + queryParams
            },
        }),
        listFeedProductsRegion: build.query<PaginationResult<IFeedProduct>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                if (params.regionId) {
                    queryParams += `&regionId=${params.regionId}`;
                }
                if (params.customFilters) {
                    queryParams += `&filters=${btoa(JSON.stringify(params.customFilters))}`
                }
                return API_URLS.feedProductsRegionPaginated + queryParams
            },
        }),
        listScheduledFeedProducts: build.query<PaginationResult<IScheduledFeedProduct>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                return API_URLS.scheduledFeedProductsPaginated + queryParams
            },
            providesTags: ["ListScheduledProducts"]
        }),
        publishScheduledFeedProduct: build.mutation<IScheduledFeedProduct, IScheduleFeedProductPublishRequest>({
            query: (body) => ({
                url: API_URLS.scheduledFeedProducts,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["ListScheduledProducts"]
        }),
        deleteScheduledFeedProduct: build.mutation<void, string>({
            query: (productId) => ({
                url: API_URLS.scheduledFeedProductId.replace(':productId', productId),
                method: 'DELETE',
            }),
            invalidatesTags: ["ListScheduledProducts"]
        }),
        listRegions: build.query<(IRegion & { _id: string })[], void>({
            query: () => API_URLS.regions,
        }),
    }),
    overrideExisting: false,
})

export const {
    useListFeedProductsQuery,
    useListScheduledFeedProductsQuery,
    usePublishScheduledFeedProductMutation,
    useDeleteScheduledFeedProductMutation,
    useListRegionsQuery,
    useListFeedProductsRegionQuery,
} = api