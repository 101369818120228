import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthScreens, AuthorizationContainerState } from './Types'
import LocalStorageService, { LocalStorageKeys } from '../../Services/LocalStorage'
import { api } from './Authorization.service'
import { IOAuth2Token } from '@esavvynpm/types'
import { clearToken } from '../LocalStorage/LocalStorage.slice'

const initialState: AuthorizationContainerState = {
    isLoggedIn: false,
    screen: AuthScreens.SPLASH,
    isLoggingOut: false,
    logoutError: null,
    token: null,
}

export const authorizationSlice = createSlice({
    name: 'Authorization',
    initialState,
    reducers: {
        setScreen(state, action: PayloadAction<AuthScreens>) {
            state.screen = action.payload
        },
        setToken(state, action: PayloadAction<IOAuth2Token>) {
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutAsync.fulfilled, (state) => {
                state.token = null
                state.isLoggedIn = false
                state.screen = AuthScreens.SIGN_IN
            })

    }
})

export const logoutAsync = createAsyncThunk(
    'authentication/logout',
    async (args, { dispatch }) => {
        await LocalStorageService.removeItem(LocalStorageKeys.AUTH_TOKEN)
        await LocalStorageService.removeItem(LocalStorageKeys.USER_ID)

        dispatch(clearToken())
        dispatch(api.util.resetApiState())
    }
)

export const {
    setScreen,
    setToken,
} = authorizationSlice.actions
export default authorizationSlice.reducer