import moment from "moment";
import { BookingTxn } from "../../Store/Booking/Types";

export default class BookingCsvParser {
    private row: string[]
    private cols = {
        eventDate: 0,
        status: 6,
        aurlId: 16,
        orderId: 1,
        commissionFeeRange: 7,
        creditSlip: 9,
    }

    constructor(row: string[]) {
        this.row = row
    }

    private getEventDate() {
        return moment(this.row[this.cols.eventDate]).format('YYYY-MM-DD');
    }

    private getStatus() {
        return this.row[this.cols.status]
    }

    private getAUrlId() {
        return this.row[this.cols.aurlId]
    }

    private getOrderId() {
        return this.row[this.cols.orderId]
    }

    private getCreditSlip() {
        return this.row[this.cols.creditSlip]
    }

    private getCommissionFee() {
        const commissionFeeRange = this.row[this.cols.commissionFeeRange]
        let commissionFee: number = 0;
        if (isNaN(+commissionFeeRange)) {
            if (commissionFeeRange.includes('-')) {
                const commissionFeeRangeParts = commissionFeeRange.split(' - ');
                if (!isNaN(+commissionFeeRangeParts[0])) {
                    commissionFee = +commissionFeeRangeParts[0];
                }
            }
        } else {
            commissionFee = +commissionFeeRange;
        }
        /* const rateData = await this.currencyExchangeService.convert('EUR_USD');
        if (rateData && rateData.rate > 0) {
            commissionFee *= rateData.rate;
        } */
        return commissionFee
    }

    getOrder(): BookingTxn {
        return {
            eventDate: this.getEventDate(),
            status: this.getStatus(),
            aurlId: this.getAUrlId(),
            orderId: this.getOrderId(),
            commissionFee: this.getCommissionFee(),
            creditSlip: this.getCreditSlip(),
        }
    }
}