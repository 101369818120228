import { Provider } from 'react-redux'
import { store } from './Store/Store'
import Authorization from './Components/Authorization'
import Preload from './Components/Preload'

function App() {
    return (
        <Provider store={store}>
            <Authorization>
                <Preload />
            </Authorization>
        </Provider>
    )
}

export default App
