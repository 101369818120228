import { createSlice } from '@reduxjs/toolkit'
import { FeedState } from './Types'
import { api } from './Feed.service'
import { IRegion } from '@esavvynpm/types'

const initialState: FeedState = {
    regions: [],
    regionIndex: {},
}

export const feedSlice = createSlice({
    name: 'Feed',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                api.endpoints.listRegions.matchPending,
                (state) => {
                    state.regions = []
                }
            )
            .addMatcher(
                api.endpoints.listRegions.matchFulfilled,
                (state, { payload }) => {
                    state.regions = payload
                    state.regionIndex = payload.reduce((acc: { [index: string]: IRegion }, region) => {
                        acc[region._id] = region
                        return acc
                    }, {})
                }
            )
            .addMatcher(
                api.endpoints.listRegions.matchRejected,
                (state) => {
                    state.regions = []
                }
            )

    }
})

export const {
} = feedSlice.actions
export default feedSlice.reducer
