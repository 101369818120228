import { configureStore } from '@reduxjs/toolkit'
import localStorageReducer from '../Store/LocalStorage/LocalStorage.slice'
import authorizationReducer from '../Store/Authorization/Authorization.slice'
import notificationReducer from './Notification/Notification.slice'
import statsReducer from './Stats/Stats.slice'
import merchantsReducer from './Merchants/Merchants.slice'
import { baseSplitApi } from '../Services/BaseApi'
import feedReducer from './Feed/Feed.slice'

export const store = configureStore({
    reducer: {
        localStorage: localStorageReducer,
        authorization: authorizationReducer,
        notification: notificationReducer,
        stats: statsReducer,
        merchants: merchantsReducer,
        feed: feedReducer,
        [baseSplitApi.reducerPath]: baseSplitApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat(baseSplitApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch