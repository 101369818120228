import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { loadLocalStorageDataAsync } from "../../Store/LocalStorage/LocalStorage.slice"
import { AuthScreens } from "../../Store/Authorization/Types"
import { setScreen, setToken } from "../../Store/Authorization/Authorization.slice"
import SignIn from "../SignIn"
import Splash from "../Splash"

function Authorization(props: { children?: any }) {

    const dispatch = useDispatch<AppDispatch>()
    const isLoadingLocalStorageData = useSelector((state: RootState) => state.localStorage.isLoadingLocalStorageData)
    const token = useSelector((state: RootState) => state.authorization.token)
    const localStorageToken = useSelector((state: RootState) => state.localStorage.token)
    const authScreen = useSelector((state: RootState) => state.authorization.screen)

    useEffect(() => {
        dispatch(loadLocalStorageDataAsync())
    }, [])

    useEffect(() => {
        if (!isLoadingLocalStorageData) {
            if (!token) {
                if (localStorageToken) {
                    try {
                        const parsedToken = JSON.parse(localStorageToken)
                        dispatch(setToken(parsedToken))
                    } catch (err) {
                        console.error('Invalid token in localStorage', (err as Error).message)
                        dispatch(setScreen(AuthScreens.SIGN_IN))
                    }
                } else {
                    dispatch(setScreen(AuthScreens.SIGN_IN))
                }
            }
        }
    }, [isLoadingLocalStorageData, token, localStorageToken])

    let screen = <Splash />

    if (!isLoadingLocalStorageData && !token) {
        switch (authScreen) {
            case AuthScreens.SIGN_IN:
                screen = <SignIn />
                break
        }
    }

    return (
        <>
            {!!token ? props.children : screen}
        </>
    )
}

export default Authorization
